
.slide {
    overflow: hidden;
    max-height: calc((703 / 1249) * 100vw);
    height: calc((703 / 1249) * 100vw);
    display: flex;
    align-items: center;
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}