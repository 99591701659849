
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Neue Montreal Medium";
  src: url("./assets/fonts/PPNeueMontreal-Medium.woff2") format("woff2"),
  url("./assets/fonts/PPNeueMontreal-Medium.woff") format("woff");
}

@font-face {
  font-family: "Neue Montreal Regular";
  src: url("./assets/fonts/PPNeueMontreal-Regular.woff2") format("woff2"),
  url("./assets/fonts/PPNeueMontreal-Regular.woff") format("woff");
}

@font-face {
  font-family: 'SpaceMono-Bold';
  font-weight: bold;
    src: url('./assets/fonts/SpaceMono-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'SpaceMono-Regular';
  font-weight: normal;
    src: url('./assets/fonts/SpaceMono-Regular.ttf') format('ttf');
  src: url('./assets/fonts/SpaceMono-Regular.ttf') format('ttf');
}


body {
  margin: 0;
  font-family: 'Neue Montreal Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  line-height: 1.55;
  

}

* {
  font-family: 'Neue Montreal Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.font-nm-rg {
  font-family: "Neue Montreal Regular", -apple-system,  sans-serif;
}


/*
'sm': '640px',
 @media => (min-width: 640px) { ... }
'md': '768px',
// => @media (min-width: 768px) { ... }
'lg': '1024px',
// => @media (min-width: 1024px) { ... }
'xl': '1280px',
// => @media (min-width: 1280px) { ... }
'2xl': '1536px',
// => @media (min-width: 1536px) { ... }
*/

.nav-title {
  width: 100%;
  height: calc(100vw / 8);
  padding: 0 1%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: calc(100vw / 10);
  line-height: calc(100vw / 8);
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

@media (min-width: 640px) {

  .nav-title {
    font-size: calc(100vh / 15);
    line-height: calc(100vh / 15);
  }
}

@media (min-width: 768px) {
  .nav-title {
    font-size: calc(100vh / 15);
    line-height: calc(100vh / 15);
  }
}

@media (min-width: 1024px) {
  .nav-title {
    height: calc(100vh / 7);
    font-size: calc(100vh / 10);
    line-height: calc(100vh / 10);
  }
}

.bb-green {
  background-color: #ff69e2;
  color: white;
}



.bg-theme.black {
  background-color: black;
}

.bg-theme.green {
  background-color: #00FFB1;
}

.bg-theme.blue {
  background-color: #0000FF;
}

.text-china-yellow__hover:hover {
  color: #EFFF33 !important;
}

.text-china-green__hover:hover {
  color: #00FFB1 !important;
}

.text-china-blue__hover:hover {
  color: #0000FF !important;
}

.bg-theme.yellow {
  background-color: #EFFF33;
}

.color-theme.blue, .color-theme.green, .color-theme.yellow {
  color: black;
}

.color-theme.black {
  color: white;
}

/* Mobile white bg */


.bg-theme-m.black , .bg-theme-m.green, .bg-theme-m.blue , .bg-theme-m.yellow {
  background-color: white;
}

.color-theme-m.blue, .color-theme-m.green, .color-theme-m.yellow, .color-theme-m.black {
  color: black;
}

@media screen and (min-width: 1024px) {
  .bg-theme-m.black {
    background-color: black;
  }

  .bg-theme-m.green {
    background-color: #00FFB1;
  }

  .bg-theme-m.blue {
    background-color: #0000FF;
  }

  .bg-theme-m.yellow {
    background-color: #EFFF33;
  }

  .color-theme-m.blue, .color-theme-m.green, .color-theme-m.yellow {
    color: black;
  }

  .color-theme-m.black {
    color: white;
  }
}

/* Desktop white bg */

.bg-theme-d.black {
  background-color: black;
}

.bg-theme-d.green {
  background-color: #00FFB1;
}

.bg-theme-d.blue {
  background-color: #0000FF;
}

.bg-theme-d.yellow {
  background-color: #EFFF33;
}

.color-theme-d.blue, .color-theme-d.green, .color-theme-d.yellow {
  color: black;
}

.color-theme-d.black {
  color: white;
}

@media screen and (min-width: 1024px) {
  .bg-theme-d.black , .bg-theme-d.green, .bg-theme-d.blue , .bg-theme-d.yellow {
    background-color: white;
  }

  .color-theme-d.blue, .color-theme-d.green, .color-theme-d.yellow, .color-theme-d.black {
    color: black;
  }
}
/* reverse */

.bg-theme-reverse.black {
  background-color: white;
}

.bg-theme-reverse.green, .bg-theme-reverse.blue, .bg-theme-reverse.yellow {
  background-color: black;
}


.color-theme-reverse.blue  {
  color: #0000FF;
}

.color-theme-reverse.green {
  color: #00FFB1;
}

.color-theme-reverse.yellow {
  color: #EFFF33;
}

.color-theme-reverse.black {
  color: black;
}


/* Specific  */

.nav-title.black, .footer.black {
  color: white;
  background-color: black;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
}

.nav-title.white-blue, .footer.white-blue {
  color: #0659d4;
  background-color: white;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
}

.border-bb-blue {
  border-color: #0659d4 !important;
}

.nav-title.black:hover {
  color: black;
  background-color: white;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
}

.nav-title.green, .footer.green {
  color: #000;
  background-color: #00FFB1;
}

.nav-title.yellow, .footer.yellow {
  color: #000;
  background-color: #EFFF33;
}

.nav-title.blue, .footer.blue {
  color: #000;
  background-color: #0000FF;
}

.nav-title.green:hover, .nav-title.blue:hover, .nav-title.yellow:hover {
  background-color: #fff;
}

.linear-gradient.green {
  background: linear-gradient(
      rgba(0, 255, 177, 0) 0%,
      rgba(0, 255, 177, 0.2) 33%,
      rgba(0, 255, 177, 0.55) 66%,
      rgba(0, 255, 177, 1) 100%
  )
}

.linear-gradient.blue {
  background: linear-gradient(
  rgba(0, 0, 255, 0) 0%,
  rgba(0, 0, 255, 0.2) 33%,
  rgba(0, 0, 255, 0.55) 66%,
  rgba(0, 0, 255, 1) 100%);
}

.linear-gradient.yellow {
  background: linear-gradient(
          rgba(239, 255, 51, 0) 0%,
          rgba(239, 255, 51, 0.2) 33%,
          rgba(239, 255, 51, 0.55) 66%,
          rgba(239, 255, 51, 1) 100%);
}

.linear-gradient.black {
  background: linear-gradient(
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0.2) 33%,
  rgba(0, 0, 0, 0.55) 66%,
  rgba(0, 0, 0, 1) 100%);;
}

.linear-gradient.black-club {
  background: linear-gradient(
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.2) 33%,
          rgba(0, 0, 0, 0.9) 66%,
          rgba(0, 0, 0, 1) 100%);;
}

.linear-gradient.full-black {
  background: #000
}

@keyframes newsBannerOne {
  from {
    transform: translateX(100%);
  } to {
      transform: translateX(-100%);
  }
}

@keyframes newsBannerTwo {
  from {
    transform: translateX(0);
  } to {
      transform: translateX(-200%);
    }
}

.news-banner-one {
  animation:  newsBannerOne 30s linear infinite reverse;
}

.news-banner-two {
  animation:  newsBannerTwo 30s linear infinite reverse;
  animation-delay: 15s;
}

/* ============= Agenda =============== */

.page-title {
  font-size: 8vh;
  line-height: 8vh;
  font-weight: 600;
}

@media (min-width: 640px) {
  .page-title {
    font-size: 16vh;
    line-height: 16vh;
  }
}

@media (min-width: 768px) {
  .page-title {
    font-size: 22vh;
    line-height:  22vh;
  }
}

@media (min-width: 1024px) {
  .page-title {
    font-size: 25vh;
    line-height: 33vh;
  }
}

.filter-button {
  font-size: calc((100vw - 4.5rem) / 15)
}

@media only screen and (min-width: 1024px) {
  .filter-button {
    font-size: calc((100vw - 4.5rem) / 30)
  }
}

/* ============= Makers =============== */

.makers-names {
  font-size: calc(100vw / 22);
}

.zie-ook {
  font-size: calc(100vw / 28);
}

.questions {
  font-size: calc(100vw / 24);
}

.intro-text{
  font-size: calc(100vw / 24);
  
}

@media screen and (min-width : 1024px) {
  .questions {
    font-size: calc(100vw / 32);
    
  }

  .intro-text{
    font-size: calc(100vw / 48);
  }
}

/* Huren font sizes */

.huren-title{
  font-size: calc(100vw / 12);
  
}

.huren-subtitle {
  font-size: calc(100vw / 18);
  
}

.huren-place-text {
  font-size: calc(100vw / 32);
}

form > div > label {
  font-size: calc(100vw / 24);
}

@media screen and (min-width : 1024px) {
  .huren-title{
    font-size: calc(100vw / 20);
    
  }

  .huren-subtitle {
    font-size: calc(100vw / 35);
    line-height: calc(100vw / 22);
  }

  .huren-place-text {
    font-size: calc(100vw / 48);
    
  }

  form > div > label {
    font-size: calc(100vw / 48);
    
  }

}

/* Intro text font sizes  */

.intro-text-title{
  font-size: calc(100vw / 12);
  
}

@media screen and (min-width : 1024px) {
  .intro-text-title{
    font-size: calc(100vw / 28);
    
  }
}

.footer-text{
  font-size: calc(100vw / 20);
  
}

.footer-text-l {
  font-size: calc(100vw / 14);
  
}

@media screen and (min-width : 1024px) {
  .footer-text{
    font-size: calc(100vw / 44);
    
  }

  .footer-text-l {
    font-size: calc(100vw / 28);
    
  }
}

/* ============= Agenda =============== */


.agenda-items{
  font-size: calc(100vw / 30);
  
}

.agenda-items-padding {
  padding: calc(100vw / 40) 0 calc(100vw / 220);
}

.agenda-items-s{
  font-size: calc(100vw / 30);
  
}

@media screen and (min-width : 1024px) {
  .agenda-items-padding {
    padding: calc(100vw / 58) 0 calc(100vw / 240);
  }

  .agenda-items{
    font-size: calc(100vw / 46);
    
  }

  .agenda-items-s{
    font-size: calc(100vw / 58);
    
  }
}

/* ============= Vacatures =============== */


.vacancy-title {
  font-weight: 600;
  font-size: calc(100vw / 30);
  line-height: calc(100vw / 36);
}

.vacancy-subtitle {
  font-weight: 600;
  font-size: calc(100vw / 30);
  
}

.vacancy-small-subtitle, .vacancy-text {
  font-size: calc(100vw / 30);
  
}

.vacancy-small-subtitle {
  font-weight: 600;
}

@media screen and (min-width: 1024px) {
  .vacancy-title {
    font-size: calc(100vw / 36);
    
  }

  .vacancy-text {
    font-size: calc(100vw / 48);
    
  }


  .vacancy-subtitle {
    font-size: calc(100vw / 48);
    
  }

  .vacancy-small-subtitle {
    font-size: calc(100vw / 64);
    
  }

}




/* ============== HUISREGELS ============== */

.huisregels-title {
  font-size: calc(100vw / 24);
  
}

li.huisregels-list-item {
  padding-left: 1em;
  text-indent: -1em;
}

li.huisregels-list-item:before {
  content: "-";
  padding-right: 1.1rem;
}

/* ============== SVG S ============== */


.agenda-svg {
  background-image: url('assets/banners/desktop/agenda.svg');
  background-repeat: no-repeat;
}

.bg-svg {
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(667 / 520 * 100vw);
}

.bg-svg.bar-bricolage {
  height: calc(667 / 375 * 100vw) !important;
}

@media only screen and (min-width: 1024px) {
  .bg-svg {
    background-size: contain;
    height: calc(1080 / 1920 * 100vw);
  }

  .bg-svg.bar-bricolage {
    height: calc(1080 / 1920 * 100vw) !important;
  }
}

.club {
  background-image: url('assets/banners/mobile/club.svg');
}

.bar-bricolage {
  background-image: url('assets/banners/mobile/bar-bricolage.svg');
}

.expo {
  background-image: url('assets/banners/mobile/expo.svg');
}

.makers {
  background-image: url('assets/banners/mobile/makers.svg');
}

.info {
  background-image: url('assets/banners/mobile/info.svg');
}

.huren {
  background-image: url('assets/banners/mobile/huren.svg');
}

.faq {
  background-image: url('assets/banners/mobile/faq.svg');
}

.huisregels {
  background-image: url('assets/banners/mobile/huisregels.svg');
}

.medialab {
  background-image: url('assets/banners/mobile/medialab.svg');
}

.cnc {
  background-image: url('assets/banners/mobile/cnc.svg');
}

@media only screen and (min-width: 1024px) {
  .club {
    background-image: url('assets/banners/desktop/club.svg');
  }

  .bar-bricolage {
    background-image: url('assets/banners/desktop/bar-bricolage.svg');
  }

  .expo {
    background-image: url('assets/banners/desktop/expo.svg');
  }

  .makers {
    background-image: url('assets/banners/desktop/inside/Makers.svg');
  }

  .info {
    background-image: url('assets/banners/desktop/info.svg');
  }

  .huren {
    background-image: url('assets/banners/desktop/huren.svg');
  }

  .faq {
    background-image: url('assets/banners/desktop/faq.svg');
  }

  .huisregels {
    background-image: url('assets/banners/desktop/huisregels.svg');
  }

  .medialab {
    background-image: url('assets/banners/desktop/medialab.svg');
  }

  .cnc {
    background-image: url('assets/banners/desktop/cnc.svg');
  }

  .expo-bg-svg {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('assets/banners/desktop/inside/Expo.svg');
    height: calc(1080 / 1920 * 100vw);
  }

  .info-bg-svg {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('assets/banners/desktop/inside/Info.svg');
    height: calc(4291 / 1920 * 100vw);
  }

}

.huren-bg-svg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('assets/banners/desktop/inside/huren.svg');
  height: calc(1080 / 1920 * 100vw);
}

.makers-bg-svg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('assets/banners/desktop/inside/Makers.svg');
  height: calc(3300 / 1920 * 100vw);
}


.makers-background-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: transparent;
}

.adriaan {
  background-image: url('assets/images/makers/adriaan.webp');
}

.atelier {
  background-image: url('assets/images/makers/atelier.webp');
}

.bianca {
  background-image: url('assets/images/makers/bianca.webp');
}

.kabunga {
  background-image: url('assets/images/makers/kabunga.webp');
}

.lander {
  background-image: url('assets/images/makers/lander.webp');
}

.nadar {
  background-image: url('assets/images/makers/nadar.webp');
}

.piet {
  background-image: url('assets/images/makers/piet.webp');
}

.sarah {
  background-image: url('assets/images/makers/sarah.webp');
}

.silas {
  background-image: url('assets/images/makers/silas.webp');
}

.simon {
  background-image: url('assets/images/makers/simon.webp');
}


.jeff-s {
  background-image: url('assets/images/makers/jeff-s.webp');
}

.hypochristmutreefuzz {
    background-image: url('assets/images/makers/hypochristmutreefuzz.webp');
}

.velofwijf {
  background-image: url('assets/images/makers/velofwijf.webp');
}

.delphine {
    background-image: url('assets/images/makers/delphine.webp');
}

.st0{clip-path:url(#SVGID_00000085237969203331087010000010395835866905733255_);}
.st1{clip-path:url(#SVGID_00000112616253031996623310000014433415260327611808_);}
.st2{fill:#3281F2;}
.st3{clip-path:url(#SVGID_00000078012556685729902020000000526010821285784198_);}
.st4{fill:#00C96A;}
.st5{fill:#FFFFFF;}
.st6{clip-path:url(#SVGID_00000000927366969658543350000012910463047405546410_);}
.st7{clip-path:url(#SVGID_00000070796717335644677690000001894918885533311913_);}
.st8{fill:none;}
.st9{fill:#DDDDDD;}
.st10{clip-path:url(#SVGID_00000015318206394651534990000004741060486606874028_);}
.st11{clip-path:url(#SVGID_00000010281999477861542980000009383027732831812253_);}
.st12{clip-path:url(#SVGID_00000064327209399976515180000015488234626464308918_);}
.st13{clip-path:url(#SVGID_00000106834665491873439800000006379480024894690233_);}
.st14{clip-path:url(#SVGID_00000108999413817011693850000013956747276373868733_);}
.st15{clip-path:url(#SVGID_00000088848305584627061570000010174136878995994292_);}
.st16{clip-path:url(#SVGID_00000067956752023068436590000000096193969516008325_);}
.st17{fill:#1D1D1B;}
.st18{clip-path:url(#SVGID_00000139253086515080740950000010773636574484086445_);}
.st19{clip-path:url(#SVGID_00000147179002734115400230000006393582580217196674_);}
.st20{clip-path:url(#SVGID_00000178183968578590296400000018400645887685367998_);}
.st21{clip-path:url(#SVGID_00000181074823477640501210000017701214129197301925_);}
.st22{clip-path:url(#SVGID_00000126309326701390142970000010595171522518555286_);}
.st23{font-family:'Neue Montreal Medium';}
.st24{font-size:310px;}
.st25{letter-spacing: -4;}

.cls-1{fill:none;}.cls-2,.cls-3{isolation:isolate;}.cls-3{font-size:60px;font-family:NeueMontreal-Medium, Neue Montreal;font-weight:500;letter-spacing:-0.05em;}.cls-4{letter-spacing:-0.01em;}.cls-5{letter-spacing:-0.01em;}.cls-6{clip-path:url(#clip-path);}

.cls-1{isolation:isolate;}


.circle {
  transform-style: preserve-3d;
  animation: animate 8s linear infinite;
}

@keyframes animate {
  0% {
    transform: perspective(100vw) rotateX(20deg) rotateY(360deg);
  }
  100% {
    transform: perspective(100vw) rotateX(20deg) rotateY(0deg);
  }
}

.nieuwsbrief-title {
    font-size: calc(100vw / 20);
}

@media screen and (min-width : 1024px) {
  .nieuwsbrief-title {
    font-size: calc(100vw / 40);
  }
}

.nieuwsbrief {
  font-size: calc(100vw / 30);
}

@media screen and (min-width : 1024px) {
  .nieuwsbrief {
    font-size: calc(100vw / 70);
  }
}


.circle .char {
  font-family: 'SpaceMono-Regular', monospace!important;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 6vw;
  padding: 0.5vw 0.4vw;
  background: #000;
  transform-style: preserve-3d;
  transform-origin: center;
  transform: rotateY(calc(var(--char-index) * 12deg)) translateZ(20vw);
}

@media screen and (min-width: 1024px) {
  .circle .char {
    font-size: 3vw;
    padding: 0.25vw 0.17vw;
    transform: rotateY(calc(var(--char-index) * 12deg)) translateZ(10vw);
  }
}

/* ============= Loading =============== */

.lds-ring {
  display: inline-block;
  position: relative;
  width:  calc(100vw / 45);
  height: calc(100vw / 45);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width:  calc(100vw / 50);
  height: calc(100vw / 50);
  margin: calc(100vw / 50 / 8);
  border: calc(100vw / 50 / 8) solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



